import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useReducer, useSelector } from "react-redux";

import { Button, Card, Divider, Grid, Stack, Typography } from "@mui/material";

//Third pary imports
import useAxiosAuth from "../../../hooks/useAxiosAuth";

const Summary = () => {
  const navigate = useNavigate();
  const { currentBhishi } = useSelector((state) => state.generalReducer);
  const [bhishiSummary, setBhishiSummary] = useState();
  const [bhishiData, setBhishiData] = useState();
  const axiosAuth = useAxiosAuth();

  const getBhishiSummary = () => {
    axiosAuth
      .get(
        "/api/BhishiAccounts/BhishiSummary?bhishiAccountId=" + currentBhishi.id
      )
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          setBhishiSummary(response.data);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBhishiData = () => {
    axiosAuth
      .get("/api/BhishiAccounts/" + currentBhishi.id)
      .then((response) => {
        setBhishiData(response);

        if (response.status === 200) {
          console.log(response.data);
          setBhishiData(response.data);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (currentBhishi.id === 0) {
      navigate("/bhishi-list");
    }
    getBhishiSummary();
    getBhishiData();
  }, []);

  return (
    <div>
      <Card
        sx={{
          marginX: 2,
          padding: 2,
          marginTop: 2,
          backgroundColor: "#1976D2",
          color: "#FFFFFF",
        }}
      >
        <Typography
          fontWeight={400}
          textAlign="center"
          sx={{ marginBottom: 1 }}
        >
          {currentBhishi.name}
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight={400}>
            From:{" "}
            {new Date(currentBhishi.startDate).toLocaleDateString("hi-IN")}
          </Typography>
          <Typography fontWeight={400}>
            To: {new Date(currentBhishi.endDate).toLocaleDateString("hi-IN")}
          </Typography>
        </Stack>
      </Card>

      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{ padding: 2, backgroundColor: "#bbdefb" }}
            onClick={() => {
              navigate("/members-accounts");
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontWeight="bold">Members</Typography>
              <Typography fontWeight="bold" color="primary">
                {bhishiSummary?.totalMemberAccounts}
              </Typography>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{ padding: 2, backgroundColor: "#bbdefb" }}
            onClick={() => {
              navigate("/loans");
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontWeight="bold">Loans</Typography>
              <Typography fontWeight="bold" color="primary">
                {bhishiData?.loanCount}
              </Typography>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign="center" fontWeight={600} color="secondary">
            Summary
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Total Contributions Collected</Typography>
            <Typography>
              ₹{" "}
              {Math.round(bhishiSummary?.totalContribution).toLocaleString(
                "en-IN",
                0
              )}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Total Loan Repayments</Typography>
            <Typography>
              ₹{" "}
              {Math.round(bhishiSummary?.totalLoanRepayments).toLocaleString(
                "en-IN",
                0
              )}
            </Typography>
          </Stack>
        </Grid>
        {bhishiSummary?.totalPrematureAccountClosureAmount < 0 ? (
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Premature Account Closure</Typography>
              <Typography>
                ₹{" "}
                {Math.round(
                  bhishiSummary?.totalPrematureAccountClosureAmount
                ).toLocaleString("en-IN", 0)}
              </Typography>
            </Stack>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontWeight={600}>Total Incoming</Typography>
            <Typography fontWeight={600}>
              ₹{" "}
              {Math.round(
                bhishiSummary?.totalContribution +
                  bhishiSummary?.totalLoanRepayments +
                  (bhishiSummary?.totalPrematureAccountClosureAmount < 0
                    ? bhishiSummary?.totalPrematureAccountClosureAmount
                    : 0)
              ).toLocaleString("en-IN", 0)}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Total Loans Given</Typography>
            <Typography>
              ₹
              {Math.round(bhishiSummary?.totalLoanGiven).toLocaleString(
                "en-IN",
                0
              )}
            </Typography>
          </Stack>
        </Grid>
        {bhishiSummary?.totalPrematureAccountClosureAmount > 0 ? (
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Premature Account Closure</Typography>
              <Typography>
                ₹
                {Math.round(
                  bhishiSummary?.totalPrematureAccountClosureAmount
                ).toLocaleString("en-IN", 0)}
              </Typography>
            </Stack>
          </Grid>
        ) : null}{" "}
        {bhishiSummary?.totaFinalClosureAmount > 0 ? (
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Final Account Closure</Typography>
              <Typography>
                ₹
                {Math.round(
                  bhishiSummary?.totaFinalClosureAmount
                ).toLocaleString("en-IN", 0)}
              </Typography>
            </Stack>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontWeight={600}>Total Outgoing</Typography>
            <Typography fontWeight={600}>
              ₹{" "}
              {Math.round(
                bhishiSummary?.totalLoanGiven +
                  (bhishiSummary?.totalPrematureAccountClosureAmount > 0
                    ? bhishiSummary?.totalPrematureAccountClosureAmount
                    : 0) +
                  bhishiSummary?.totaFinalClosureAmount
              ).toLocaleString("en-IN", 0)}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontWeight={600} color="primary">
              {currentBhishi.status == 0 ? "Balance" : "Profit"}
            </Typography>
            <Typography fontWeight={600} color="primary">
              ₹{" "}
              {Math.round(
                bhishiSummary?.totalContribution +
                  bhishiSummary?.totalLoanRepayments -
                  bhishiSummary?.totalLoanGiven -
                  bhishiSummary?.totalPrematureAccountClosureAmount -
                  bhishiSummary?.totaFinalClosureAmount
              ).toLocaleString("en-IN", 0)}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            fullWidth
            sx={{ marginTop: 5 }}
            onClick={() => {
              navigate("/bhishi-list");
            }}
          >
            Change Bhishi
          </Button>
        </Grid>
        {currentBhishi.status == 0 && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              fullWidth
              disabled={bhishiSummary == undefined}
              color="error"
              onClick={() => {
                let balance =
                  bhishiSummary?.totalContribution +
                  bhishiSummary?.totalLoanRepayments -
                  bhishiSummary?.totalLoanGiven -
                  bhishiSummary?.totalPrematureAccountClosureAmount;
                navigate(
                  "/bhishi-closure?Id=" +
                    currentBhishi.id +
                    "&balance=" +
                    balance
                );
              }}
            >
              Close Bhishi
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Summary;
