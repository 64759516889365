import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

//Material ui imports
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  Skeleton,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArticleIcon from "@mui/icons-material/Article";
import PublishIcon from "@mui/icons-material/Publish";

import { useTheme } from "@mui/material/styles";

//Project imports
import MemberAccountForm from "./MemberAccountForm";
import config from "../../../config";

//Third pary imports
import useAxiosAuth from "../../../hooks/useAxiosAuth";
//-------------------------------------------Member Accounts List Page---------------------------------------------//

const MembersAccounts = () => {
  //const and other declarations
  const theme = useTheme();
  const navigate = useNavigate();
  const axiosAuth = useAxiosAuth();
  const pageSize = 80;
  const { currentBhishi } = useSelector((state) => state.generalReducer);

  //states
  const [memberAccountData, setMemberAccountData] = useState([]);
  const [memberAccountCount, setMemberAccountCount] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedMemberAccount, setSelectedMemberAccount] = useState({
    memberName: 0,
  });
  const [searchText, setSearchText] = useState("");
  const [openMemberAccountForm, setOpenMemberAccountForm] = useState(false);
  const [openSuccessMsg, setOpenSuccessMsg] = useState(false);

  //functions
  const getMemberAccountData = (skip) => {
    let filterData = {
      searchText: searchText,
      isDeleted: false,
      skip: skip,
      bhishiAccountId: currentBhishi.id,
      take: pageSize,
    };
    axiosAuth
      .post("/api/MemberAccounts/GetFilterPaginated", filterData)
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          setMemberAccountCount(response.data.count);
          setMemberAccountData(response.data.memberAccounts);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = () => {
    axiosAuth
      .delete("/api/MemberAccounts/Soft-Delete/" + selectedMemberAccount.id)
      .then((response) => {
        setOpenDelete(false);
        getMemberAccountData(0);
      })
      .catch((error) => {
        console.log(error);
        setOpenDelete(false);
      });
  };

  const handleCancelDelete = () => {
    setOpenDelete(false);
  };

  //useEffect methods
  useEffect(() => {
    getMemberAccountData(0);
  }, [searchText]);

  return (
    <div>
      <Grid container spacing={3} sx={{ padding: 2 }}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            direction: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography textAlign="center" variant="h6">
            Members Accounts
          </Typography>
          <Button
            variant="outlined"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            disabled={currentBhishi.status == 1}
            startIcon={<PublishIcon />}
            size="large"
            onClick={() => {
              setOpenMemberAccountForm(true);
            }}
          >
            Import
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            fullWidth
            disabled={currentBhishi.status == 1}
            startIcon={<AddIcon />}
            size="large"
            onClick={() => {
              navigate("/members-form?type=full");
            }}
          >
            Add New
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
            variant="outlined"
            fullWidth
            placeholder="Search by name or account no."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {memberAccountData !== undefined ? (
            <Box>
              {memberAccountData.map((member, index) => (
                <Card
                  variant="outlined"
                  sx={{
                    paddingY: 2,
                    paddingRight: 2,
                    mb: 2,
                    background: "#E8E8E8",
                  }}
                  key={index}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    columnspacing={1}
                  >
                    <Grid item xs={2} textAlign="center">
                      <Box
                        sx={{
                          backgroundColor:
                            member.memberType == 1 ? "#b30086" : "#1976D2",
                          paddingY: 1,
                        }}
                      >
                        <Typography sx={{ color: "#FFFFFF", fontSize: "14px" }}>
                          {member.accountNumber}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={8} align="center">
                      <Stack>
                        <Typography fontWeight="bold">
                          {member.memberName}
                        </Typography>
                        <Typography color="#616161">
                          {member.memberPersonalMobileNumber}
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item xs={2}>
                      <Stack
                        justifyContent="center"
                        alignItems="flex-end"
                        spacing={1}
                      >
                        <Button
                          onClick={() => {
                            navigate("/member-account-details?Id=" + member.id);
                          }}
                          sx={{ bgcolor: "#bbdefb" }}
                          variant="outlined"
                        >
                          <ArticleIcon color="primary" fontSize="small" />
                        </Button>
                        <Button
                          sx={{ bgcolor: "#bbdefb" }}
                          variant="outlined"
                          onClick={() => {
                            setSelectedMemberAccount(member);
                            setOpenDelete(true);
                          }}
                        >
                          <DeleteIcon color="error" fontSize="small" />
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              ))}
            </Box>
          ) : (
            <Stack justifyContnet="center" alignItems="center" spacing={3}>
              <Typography variant="h5">Loading Member Accounts</Typography>
              <CircularProgress />
            </Stack>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {memberAccountCount !== undefined ? (
            <Pagination
              variant="outlined"
              shape="rounded"
              count={Math.ceil(memberAccountCount / pageSize)}
              color="primary"
              size="large"
              onChange={(event, page) => {
                getMemberAccountData((page - 1) * pageSize);
              }}
            />
          ) : null}
        </Grid>
      </Grid>

      <Dialog
        open={openMemberAccountForm}
        onClose={() => {
          setOpenMemberAccountForm(false);
        }}
      >
        <DialogContent>
          <MemberAccountForm
            onSave={() => {
              setOpenSuccessMsg(true);
              getMemberAccountData();
              setOpenMemberAccountForm(false);
            }}
            onCancel={() => {
              setOpenMemberAccountForm(false);
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openDelete} onClose={handleCancelDelete} fullWidth>
        <DialogTitle>Delete Member Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete member account:
            <span style={{ color: "#E90000" }}>
              {selectedMemberAccount.memberName}
            </span>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} variant="contained">
            Yes
          </Button>
          <Button onClick={handleCancelDelete} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSuccessMsg}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSuccessMsg(false);
        }}
      >
        <Alert
          variant="filled"
          onClose={() => {
            setOpenSuccessMsg(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Member imported successfully!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MembersAccounts;
