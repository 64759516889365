import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

//Material ui imports
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";

//Project imports
import config from "../../../config";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import Dialogs from "../../../components/Dialogs";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import ClosingStatement from "./ClosingStatement";
import { useReactToPrint } from "react-to-print";
import MemberStatement from "./MemberStatement";

const BhishiClosureForm = () => {
  const navigate = useNavigate();
  const axiosAuth = useAxiosAuth();
  const { currentBhishi } = useSelector((state) => state.generalReducer);
  const componentRef = useRef();
  const componentRefMember = useRef();

  const [searchParams, setSearchParams] = useSearchParams();

  const [closingStatement, setClosingStatement] = useState();
  const [closureDate, setClosureDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [interestRate, setInterestRate] = useState(0);
  //const [currentBhishi, setcurrentBhishi] = useState();
  const [closeWarningDialog, setCloseWarningDialog] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [closing, setClosing] = useState(false);
  const [printCmd, setPrintCmd] = useState(false);
  const [balance, setBalance] = useState(0);
  const [toPay, setToPay] = useState(0);
  const [toReceive, setToReceive] = useState(0);
  const [individualReportData, setIndividualReportData] = useState();

  //print states
  const [printPrinciplePending, setPrintPrinciplePending] = useState(0);
  const [printDivident, setPrintDivident] = useState(0);
  const [printInterestPending, setPrintInterestPending] = useState(0);
  const [printSettlementAmount, setPrintSettlementAmount] = useState(0);

  const onPrintComplete = () => {
    setPrintCmd(false);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: onPrintComplete,
  });

  useEffect(() => {
    if (printCmd && closingStatement != undefined) {
      handlePrint();
    }
  }, [printCmd]);

  const onMemberPrintComplete = () => {
    setPrintCmd(false);
  };

  const handleMemberPrint = useReactToPrint({
    content: () => componentRefMember.current,
    onAfterPrint: onMemberPrintComplete,
  });

  useEffect(() => {
    if (individualReportData != undefined) {
      handleMemberPrint();
    }
  }, [individualReportData]);

  useEffect(() => {
    setBalance(searchParams.get("balance"));
  }, [searchParams.get("balance")]);

  const closeBhishi = () => {
    setClosing(true);
    setCloseWarningDialog(false);
    let requestModel = {
      bhishiAccountId: currentBhishi.id,
      interestRate: interestRate,
      closingDate: closureDate,
      bufferDays: 0,
    };
    console.log(requestModel);
    axiosAuth
      .post("/api/BhishiAccounts/BhishiClosure-Transaction", requestModel)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setClosing(false);
          if (response.data == true) {
            navigate("/bhishi-list");
          } else {
            alert("Server error, please try again after some time!");
          }
        } else {
          console.log("Error");
          setClosing(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setClosing(false);
      });
  };

  const getClosingStatement = () => {
    setSubmitting(true);
    let requestModel = {
      bhishiAccountId: currentBhishi.id,
      interestRate: interestRate,
      closingDate: closureDate,
      bufferDays: 0,
    };
    console.log(requestModel);
    axiosAuth
      .post("/api/BhishiAccounts/BhishiSettlement", requestModel)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          setSubmitting(false);
          setClosingStatement(response.data);
          let toPayData = 0;
          let toReceiveData = 0;
          response.data.forEach((element) => {
            if (element.settlementAmount > 0) {
              toPayData += element.settlementAmount;
            } else {
              toReceiveData += element.settlementAmount;
            }
          });
          setToPay(toPayData);
          setToReceive(toReceiveData);
        } else {
          setSubmitting(false);
          console.log("Error");
        }
      })
      .catch((error) => {
        setSubmitting(false);
        console.log(error);
      });
  };

  return (
    <>
      <Grid container spacing={2} sx={{ padding: 3 }}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            direction: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography textAlign="center" variant="h6">
            Bhishi Details
          </Typography>
          <Button
            variant="outlined"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{
              padding: 2,
              backgroundColor: "#1976D2",
              color: "#FFFFFF",
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontWeight={400}>{currentBhishi.name}</Typography>
              <Typography fontWeight={400}>
                From:{" "}
                {new Date(currentBhishi.startDate).toLocaleDateString("hi-IN")}
              </Typography>
              <Typography fontWeight={400}>
                To:{" "}
                {new Date(currentBhishi.endDate).toLocaleDateString("hi-IN")}
              </Typography>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="date"
            label="Closure Date *"
            onChange={(e) => {
              setClosureDate(e.target.value);
            }}
            value={closureDate}
            fullWidth
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="number"
            label="Divident (%) *"
            onChange={(e) => {
              setInterestRate(e.target.value);
            }}
            value={interestRate}
            fullWidth
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign="center">
            It may take upto few minutes to complete the settlement
            calculations...
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            fullWidth
            variant="contained"
            disabled={closing}
            loading={submitting}
            onClick={() => {
              getClosingStatement();
            }}
          >
            Get Closing StateMent
          </LoadingButton>
        </Grid>
        {closingStatement != undefined && (
          <>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                disabled={closing}
                onClick={() => {
                  setPrintCmd(true);
                }}
              >
                Print Closing Statement
              </Button>
              <div style={{ display: "none" }}>
                <ClosingStatement
                  ref={componentRef}
                  reportData={closingStatement}
                  toPay={toPay}
                  toReceive={toReceive}
                  balance={
                    Number(balance) + -Math.round(toPay) - Math.round(toReceive)
                  }
                  divident={interestRate}
                  currentBhishi={currentBhishi}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                loading={closing}
                color="error"
                variant="outlined"
                onClick={() => {
                  setCloseWarningDialog(true);
                }}
              >
                Close Bhishi
              </LoadingButton>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          {closingStatement != undefined ? (
            <>
              <Card variant="outlined" sx={{ padding: 1, marginBottom: 1 }}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ fontWeight: "700" }}>
                    Current Bal:{" "}
                  </Typography>
                  <Typography>
                    {Number(balance).toLocaleString("en-IN")}
                  </Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ fontWeight: "700" }}>To Pay: </Typography>
                  <Typography>
                    {Math.round(toPay).toLocaleString("en-IN", 0)}
                  </Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ fontWeight: "700" }}>
                    To Receive:{" "}
                  </Typography>
                  <Typography>
                    {Math.round(-toReceive).toLocaleString("en-IN", 0)}
                  </Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ fontWeight: "700" }}>Profit: </Typography>
                  <Typography>
                    {(
                      Number(balance) +
                      -Math.round(toPay) -
                      Math.round(toReceive)
                    ).toLocaleString("en-IN", 0)}
                  </Typography>
                </Stack>
              </Card>

              {closingStatement.map((statement, index) => {
                return (
                  <Accordion
                    variant="outlined"
                    key={index}
                    sx={{ marginBottom: 1, backgroundColor: "#F0F0F0" }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Stack>
                        <Typography>
                          <b>{statement.memeberAccount.memberName}</b> (
                          {statement.memeberAccount.accountNumber})
                        </Typography>
                        <Typography
                          variant="h6"
                          color={
                            statement.settlementAmount < 0
                              ? "success.light"
                              : "error.light"
                          }
                        >
                          {statement.settlementAmount > 0 ? "Pay:" : "Receive:"}{" "}
                          ₹{" "}
                          {Math.abs(statement.settlementAmount).toLocaleString(
                            "en-IN",
                            0
                          )}
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginY: 1 }}
                        color="error.main"
                      >
                        <Typography>Total Contribution</Typography>
                        <Typography>
                          ₹{" "}
                          {statement.totalContribution.toLocaleString(
                            "en-IN",
                            0
                          )}
                        </Typography>
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginY: 1 }}
                      >
                        <Typography>Total Contribution Penalty</Typography>
                        <Typography>
                          ₹{" "}
                          {statement.totalContributionPenalty.toLocaleString(
                            "en-IN",
                            0
                          )}
                        </Typography>
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginY: 1 }}
                        color="error.main"
                      >
                        <Typography>Dividend</Typography>
                        <Typography>
                          ₹{" "}
                          {statement.totalContributionInterestAmmount.toLocaleString(
                            "en-IN",
                            0
                          )}
                        </Typography>
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginY: 1 }}
                      >
                        <Typography>Total Loan Given</Typography>
                        <Typography>
                          ₹{" "}
                          {statement.totalLoanTaken.toLocaleString("en-IN", 0)}
                        </Typography>
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginY: 1 }}
                      >
                        <Typography>Total Loan Principle Returned</Typography>
                        <Typography>
                          ₹{" "}
                          {statement.totalLoanPrinciplePaid.toLocaleString(
                            "en-IN",
                            0
                          )}
                        </Typography>
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginY: 1 }}
                      >
                        <Typography>Total Loan Interest Returned</Typography>
                        <Typography>
                          ₹{" "}
                          {statement.totalLoanInterestPaid.toLocaleString(
                            "en-IN",
                            0
                          )}
                        </Typography>
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginY: 1 }}
                        color="success.light"
                      >
                        <Typography>Total Loan Principle Pending</Typography>
                        <Typography>
                          ₹{" "}
                          {statement.totalLoanPrinciplePending.toLocaleString(
                            "en-IN",
                            0
                          )}
                        </Typography>
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginY: 1 }}
                        color="success.light"
                      >
                        <Typography>Total Loan Interest Pending</Typography>
                        <Typography>
                          ₹{" "}
                          {statement.totalLoanInterestPending.toLocaleString(
                            "en-IN",
                            0
                          )}
                        </Typography>
                      </Stack>

                      <Button
                        variant="outlined"
                        onClick={() => {
                          setPrintPrinciplePending(
                            statement.totalLoanPrinciplePending
                          );
                          setPrintDivident(
                            statement.totalContributionInterestAmmount
                          );
                          setPrintInterestPending(
                            statement.totalLoanInterestPending
                          );
                          setPrintSettlementAmount(statement.settlementAmount);
                          axiosAuth
                            .post(
                              "/api/MemberAccounts/MemberAccountReport?memberAccountId=" +
                                statement.memeberAccount.id +
                                "&fromDate=" +
                                currentBhishi.startDate +
                                "&toDate=" +
                                closureDate
                            )
                            .then((response) => {
                              if (response.status === 200) {
                                console.log(response.data);
                                setIndividualReportData(response.data);
                              } else {
                                console.log("Error");
                              }
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        }}
                      >
                        Print Statement
                      </Button>
                      {statement != undefined && (
                        <div style={{ display: "none" }}>
                          <MemberStatement
                            ref={componentRefMember}
                            reportData={individualReportData}
                            currentBhishi={currentBhishi}
                            divident={printDivident}
                            interestPending={printInterestPending}
                            principlePending={printPrinciplePending}
                            settlementAmount={printSettlementAmount}
                          />
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </>
          ) : null}
        </Grid>
      </Grid>

      <Dialogs
        openDialog={closeWarningDialog}
        closeDialog={() => setCloseWarningDialog(false)}
        title={"Warnining!"}
        contentText={`Are you sure you want to close this Bhishi? Once closed you cannot revert back!`}
        handleYes={() => closeBhishi()}
        handleNo={() => setCloseWarningDialog(false)}
      />
    </>
  );
};

export default BhishiClosureForm;
