import {
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const contibution = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10, 0,
  1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10,
];
const MemberStatement = React.forwardRef((props, ref) => {
  const {
    reportData,
    divident,
    interestPending,
    principlePending,
    settlementAmount,
  } = props;
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"30px"} ${"30px"} ${"30px"} !important; }`;
  };
  const classes = {
    narrowCell: {
      whiteSpace: "normal",
      wordWrap: "break-word",
      maxWidth: "70px",
      padding: "5px",
      fontSize: "12px",
    },
    priceCell: {
      whiteSpace: "normal",
      wordWrap: "break-word",
      maxWidth: "80px",
      padding: "5px",
      fontSize: "12px",
    },
    fullCell: {
      whiteSpace: "normal",
      wordWrap: "break-word",
      padding: "5px",
      fontSize: "12px",
    },
  };
  return (
    <div ref={ref}>
      <style>{getPageMargins()}</style>
      <>
        <Card
          variant="outlined"
          sx={{
            padding: 1,
            backgroundColor: "#e3f2fd",
          }}
        >
          <Stack direction="row" spacing={2}>
            <Typography>
              {reportData?.memberAccount.memberName} (
              {reportData?.memberAccount.accountNumber})
            </Typography>
            <Typography fontWeight="bold">|</Typography>
            <Typography>
              Mobile: {reportData?.memberAccount.memberPersonalMobileNumber}
            </Typography>
          </Stack>
        </Card>
        <Card
          variant="outlined"
          sx={{
            padding: 1,
            mt: 1,
            mb: 1,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "700" }}>
            Summary
          </Typography>
          <Stack direction="row" spacing={2}>
            <Typography>
              <b>Monthly Contribution:</b> {reportData?.totalContribution}
            </Typography>
            <Typography>
              <b>Penalty:</b> {reportData?.totalPenalty}
            </Typography>
            <Typography>
              <b>Total Contribution:</b>{" "}
              {reportData?.totalContribution + reportData?.totalPenalty}
            </Typography>
            <Typography>
              <b>Divident:</b> {divident}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography>
              <b>Loan Amount:</b> {reportData?.totalLoanAmount}
            </Typography>
            <Typography>
              <b>Principle Returned:</b> {reportData?.totalPrincipleReturned}
            </Typography>
            <Typography>
              <b>Interest Returned:</b> {reportData?.totalInterestReturned}
            </Typography>
            <Typography>
              <b>Principle Pending:</b> {principlePending}
            </Typography>
            <Typography>
              <b>Interest Pending:</b> {interestPending}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography>
              <b>Settlement Amount:</b>{" "}
              {settlementAmount > 0 ? "Pay:" : "Receive:"} ₹{" "}
              {Math.abs(settlementAmount).toLocaleString("en-IN", 0)}
            </Typography>
          </Stack>
        </Card>
        <table style={{ width: "100%" }}>
          <thead>
            {/* <tr>
              <td>
                <div style={{ height: "60px" }}></div>
              </td>
            </tr> */}
          </thead>
          <tbody>
            <tr>
              <td>
                <TableContainer>
                  <Table size="small">
                    <TableHead sx={{ backgroundColor: "#1976D2" }}>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ color: "#ffffff" }}
                          style={classes.narrowCell}
                        >
                          Sr. No.
                        </TableCell>
                        <TableCell
                          align="left"
                          style={classes.fullCell}
                          sx={{ color: "#ffffff" }}
                        >
                          Date
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Transaction Type
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Contribution
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Penalty
                        </TableCell>
                        <TableCell
                          x
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Sub Total
                        </TableCell>
                        <TableCell
                          x
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Loan
                        </TableCell>
                        <TableCell
                          x
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Principle Repayment
                        </TableCell>
                        <TableCell
                          x
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Interest Repayment
                        </TableCell>
                        <TableCell
                          x
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Total Loan Repayment
                        </TableCell>
                        {reportData?.memberAccount.status == 2 ||
                          (reportData?.memberAccount.status == 3 && (
                            <TableCell
                              x
                              align="right"
                              sx={{ color: "#ffffff" }}
                              style={classes.priceCell}
                            >
                              Closing Amount
                            </TableCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportData?.memberAccountReportRows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={classes.narrowCell}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell align="left" style={classes.fullCell}>
                            {new Date(row.transactionDate).toLocaleDateString(
                              "hi-IN"
                            )}
                          </TableCell>
                          <TableCell align="right" style={classes.priceCell}>
                            {row.transactionType}
                          </TableCell>
                          <TableCell align="right" style={classes.priceCell}>
                            {row.contribution > 0 ? row.contribution : "-"}
                          </TableCell>
                          <TableCell align="right" style={classes.priceCell}>
                            {row.penalty > 0 ? row.penalty : "-"}
                          </TableCell>
                          <TableCell align="right" style={classes.priceCell}>
                            {row.contributionSubTotal > 0
                              ? row.contributionSubTotal
                              : "-"}
                          </TableCell>
                          <TableCell align="right" style={classes.priceCell}>
                            {Math.abs(row.loan) > 0 ? Math.abs(row.loan) : "-"}
                          </TableCell>
                          <TableCell align="right" style={classes.priceCell}>
                            {row.principleReturned > 0
                              ? row.principleReturned
                              : "-"}
                          </TableCell>
                          <TableCell align="right" style={classes.priceCell}>
                            {row.interestReturned > 0
                              ? row.interestReturned
                              : "-"}
                          </TableCell>
                          <TableCell align="right" style={classes.priceCell}>
                            {row.totalLoanReturned > 0
                              ? row.totalLoanReturned
                              : "-"}
                          </TableCell>
                          {reportData?.memberAccount.status == 2 ||
                            (reportData?.memberAccount.status == 3 && (
                              <TableCell
                                style={classes.priceCell}
                                align="right"
                              >
                                {row.closingAmount > 0
                                  ? row.closingAmount
                                  : "-"}
                              </TableCell>
                            ))}
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell
                          colSpan={3}
                          align="right"
                          component="th"
                          scope="row"
                          style={classes.narrowCell}
                          sx={{ fontWeight: "bold" }}
                        >
                          Total
                        </TableCell>
                        <TableCell align="right" style={classes.priceCell}>
                          {reportData?.memberAccountReportSumRow.contribution >
                          0
                            ? reportData?.memberAccountReportSumRow.contribution
                            : "-"}
                        </TableCell>
                        <TableCell align="right" style={classes.priceCell}>
                          {reportData?.memberAccountReportSumRow.penalty > 0
                            ? reportData?.memberAccountReportSumRow.penalty
                            : "-"}
                        </TableCell>
                        <TableCell align="right" style={classes.priceCell}>
                          {reportData?.memberAccountReportSumRow
                            .contributionSubTotal > 0
                            ? reportData?.memberAccountReportSumRow
                                .contributionSubTotal
                            : "-"}
                        </TableCell>
                        <TableCell align="right" style={classes.priceCell}>
                          {Math.abs(
                            reportData?.memberAccountReportSumRow.loan
                          ) > 0
                            ? Math.abs(
                                reportData?.memberAccountReportSumRow.loan
                              )
                            : "-"}
                        </TableCell>
                        <TableCell align="right" style={classes.priceCell}>
                          {reportData?.memberAccountReportSumRow
                            .principleReturned > 0
                            ? reportData?.memberAccountReportSumRow
                                .principleReturned
                            : "-"}
                        </TableCell>
                        <TableCell align="right" style={classes.priceCell}>
                          {reportData?.memberAccountReportSumRow
                            .interestReturned > 0
                            ? reportData?.memberAccountReportSumRow
                                .interestReturned
                            : "-"}
                        </TableCell>
                        <TableCell align="right" style={classes.priceCell}>
                          {reportData?.memberAccountReportSumRow
                            .totalLoanReturned > 0
                            ? reportData?.memberAccountReportSumRow
                                .totalLoanReturned
                            : "-"}
                        </TableCell>
                        {reportData?.memberAccount.status == 2 ||
                          (reportData?.memberAccount.status == 3 && (
                            <TableCell style={classes.priceCell} align="right">
                              {reportData?.memberAccountReportSumRow
                                .closingAmount > 0
                                ? reportData?.memberAccountReportSumRow
                                    .closingAmount
                                : "-"}
                            </TableCell>
                          ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    </div>
  );
});

export default MemberStatement;
