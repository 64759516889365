import {
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const contibution = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10, 0,
  1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10,
];
const ClosingStatement = React.forwardRef((props, ref) => {
  const { reportData, toPay, toReceive, balance, divident, currentBhishi } =
    props;
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"30px"} ${"30px"} ${"30px"} !important; }`;
  };
  const classes = {
    narrowCell: {
      whiteSpace: "normal",
      wordWrap: "break-word",
      width: "40px",
      padding: "5px",
      fontSize: "12px",
    },
    priceCell: {
      whiteSpace: "normal",
      wordWrap: "break-word",
      width: "80px",
      padding: "5px",
      fontSize: "12px",
    },
    fullCell: {
      whiteSpace: "normal",
      wordWrap: "break-word",
      padding: "5px",
      fontSize: "12px",
    },
  };
  return (
    <div ref={ref}>
      <style>{getPageMargins()}</style>
      <>
        <Card
          variant="outlined"
          sx={{
            padding: 2,
            backgroundColor: "#e3f2fd",
            mb: 1,
          }}
        >
          <Stack direction="row" spacing={2}>
            <Typography fontWeight="bold">{currentBhishi.name}</Typography>
            <Typography fontWeight="bold">|</Typography>
            <Typography fontWeight="bold">
              Pay: {Math.round(toPay).toLocaleString("en-IN", 0)}
            </Typography>
            <Typography fontWeight="bold">|</Typography>
            <Typography fontWeight="bold">
              Receive: {Math.round(-toReceive).toLocaleString("en-IN", 0)}
            </Typography>
            <Typography fontWeight="bold">|</Typography>
            <Typography fontWeight="bold">
              Profit: {Math.round(balance).toLocaleString("en-IN", 0)}
            </Typography>
          </Stack>
        </Card>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <TableContainer>
                  <Table size="small">
                    <TableHead sx={{ backgroundColor: "#1976D2" }}>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{ color: "#ffffff" }}
                          style={classes.narrowCell}
                        >
                          #
                        </TableCell>
                        <TableCell
                          align="left"
                          style={classes.fullCell}
                          sx={{ color: "#ffffff" }}
                        >
                          Member
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Contribution
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Penalty
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Loan Amount
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Total Loan Repayment
                        </TableCell>
                        <TableCell
                          x
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Loan Principle Pending
                        </TableCell>
                        <TableCell
                          x
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Loan Interest Pending
                        </TableCell>
                        <TableCell
                          x
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Divident
                        </TableCell>
                        <TableCell
                          x
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Settlement Amount
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportData?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            align="left"
                            component="th"
                            scope="row"
                            style={classes.narrowCell}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            align="left"
                            component="th"
                            scope="row"
                            style={classes.narrowCell}
                          >
                            {row.memeberAccount.memberName}
                          </TableCell>
                          <TableCell
                            align="right"
                            component="th"
                            scope="row"
                            style={classes.narrowCell}
                          >
                            {Math.round(row.totalContribution).toLocaleString(
                              "en-IN",
                              0
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            component="th"
                            scope="row"
                            style={classes.narrowCell}
                          >
                            {Math.round(
                              row.totalContributionPenalty
                            ).toLocaleString("en-IN", 0)}
                          </TableCell>
                          <TableCell
                            align="right"
                            component="th"
                            scope="row"
                            style={classes.narrowCell}
                          >
                            {Math.round(row.totalLoanTaken).toLocaleString(
                              "en-IN",
                              0
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            component="th"
                            scope="row"
                            style={classes.narrowCell}
                          >
                            {Math.round(
                              row.totalLoanPrinciplePaid +
                                row.totalLoanInterestPaid
                            ).toLocaleString("en-IN", 0)}
                          </TableCell>
                          <TableCell
                            align="right"
                            component="th"
                            scope="row"
                            style={classes.narrowCell}
                          >
                            {Math.round(
                              row.totalLoanPrinciplePending
                            ).toLocaleString("en-IN", 0)}
                          </TableCell>
                          <TableCell
                            align="right"
                            component="th"
                            scope="row"
                            style={classes.narrowCell}
                          >
                            {Math.round(
                              row.totalLoanInterestPending
                            ).toLocaleString("en-IN", 0)}
                          </TableCell>
                          <TableCell
                            align="right"
                            component="th"
                            scope="row"
                            style={classes.narrowCell}
                          >
                            {Math.round(
                              row.totalContributionInterestAmmount
                            ).toLocaleString("en-IN", 0)}
                          </TableCell>
                          <TableCell
                            align="right"
                            component="th"
                            scope="row"
                            style={classes.narrowCell}
                          >
                            {row.settlementAmount > 0 ? "Pay:" : "Receive:"} ₹{" "}
                            {Math.round(row.settlementAmount).toLocaleString(
                              "en-IN",
                              0
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      {/* <TableRow>
                        <TableCell
                          colSpan={3}
                          align="right"
                          component="th"
                          scope="row"
                          style={classes.narrowCell}
                          sx={{ fontWeight: "bold" }}
                        >
                          Total
                        </TableCell>
                        <TableCell
                          style={classes.priceCell}
                          sx={{ fontWeight: "bold" }}
                          align="right"
                        >
                          {reportData?.memberAccountReportSumRow.amount}
                        </TableCell>
                        <TableCell
                          style={classes.priceCell}
                          sx={{ fontWeight: "bold" }}
                          align="right"
                        >
                          {reportData?.memberAccountReportSumRow.interestOrPenalty}
                        </TableCell>
                        <TableCell
                          style={classes.priceCell}
                          sx={{ fontWeight: "bold" }}
                          align="right"
                        >
                          {reportData?.memberAccountReportSumRow.subTotal}
                        </TableCell>
                      </TableRow> */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    </div>
  );
});

export default ClosingStatement;
