import React from "react";

//MUI imports
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";

const Dialogs = (props) => {
  const { openDialog, closeDialog, title, contentText, handleYes, handleNo } =
    props;
  return (
    <div>
      <Dialog open={openDialog} onClose={closeDialog} fullWidth>
        <DialogTitle>
          <Typography variant="h5" color="secondary" sx={{ marginTop: 1 }}>
            {title}
          </Typography>
          <Divider sx={{ marginTop: 2 }} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button onClick={handleYes} variant="contained">
            Yes
          </Button>
          <Button onClick={handleNo} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Dialogs;
