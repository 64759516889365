import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";

//const BASE_URL = "https://localhost:7291";
//const BASE_URL = "https://bhishiappbetest.agsapplications.com";
const BASE_URL = "https://smbe.agsapplications.com";

export const axiosAuth = axios.create({
  baseURL: BASE_URL,
  headers: { ContentType: "application/json" },
  withCredentials: true,
});

export default axios.create({
  baseURL: BASE_URL,
});
